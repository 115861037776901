<template>
    <VanCollapseItem :name="name" :title="term.title" title-class="flex-grow">
        <template #title>
            <span>{{term.title}}</span>
            <VanIcon v-if="confirmed" name="success" style="margin-left: 5px;"></VanIcon>
        </template>
        <div class="rules-container">
            <Markdown :content="term.content"></Markdown>
        </div>
        <div style="display:flex;font-size:0.8rem;justify-content: space-between;gap:10px; align-items: center;margin-top: 1.5rem;">
            <span>{{t('Wersja: ')}}{{term.date && $dayjs(term.date).format('YYYY-MM-DD') || '1.0'}}</span>
            <div style="display: flex;justify-content: end; gap:10px;">
                <van-button v-if="!confirmed && term.date" round size="small" type="primary" style="padding:0 1rem;" @click="confirmed=true">
                    {{t('Potwierdź')}}</van-button>
                <span v-if="confirmed">{{$dayjs(confirmDate).locale(locale.lang).format('DD MMM YYYY')}}
                </span>
                <VanCheckbox v-if="confirmed" v-model="confirmed" :disabled="confirmed"></VanCheckbox>
            </div>
        </div>
        <template #value>
            <span v-if="!confirmed">{{ t('...czytaj') }}</span>
        </template>
    </VanCollapseItem>
</template>

<script setup>
const props = defineProps({
    name: String,
    open: Boolean,
    term: Object,
    profile: Object,
})
const confirmed = computed({
    get(){
        return props.profile?.terms?.[props.name] > props.term.date
    },
    set(value){
        _set(props.profile,`terms[${props.name}]`,value?(new Date()):null)
    }
})
const confirmDate = computed(()=>props.profile?.terms?.[props.name])

</script>

<style scoped>
.rules-container {
    background-color: var(--van-background);
    height:40vh;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 1rem;
    margin: -1rem 0;
    font-size:0.6rem;
}
</style>