<template>
    <VanField
            :model-value="phone"
            @update:model-value="onUpdate"
            :error-message="errorMessage"
            :error="error"
            :formatter="formatter"
            type="tel"
            format-trigger="onChange"
            @blur="onBlur">
        <template #label>
            <slot name="label">
                {{label}}
            </slot>
        </template>
    </VanField>
</template>

<script setup>
const props = defineProps({
    phoneNumber: String,
    phoneVerified: Boolean,
    required:Boolean,
    check: Boolean,
    verify: Boolean,
    strict:Boolean,
    label: String,
})
const emit = defineEmits(['update:phone-number'])
const phone = ref('')
const errorMessage = ref((_isEmpty(props.phoneNumber) && props.required )?t('Numer telefonu jest wymagany'):null)
const error = ref(_isEmpty(props.phoneNumber)&& props.required )
const checking = ref(false)
function onUpdate(val){
    const val2 = val.replace(/\+48/,'').replace(/\D*/g,'')
    const matchExact = val2.match(/(45|50|51|53|57|60|66|69|72|73|78|79|88)\d{7}/)?.[0]||''
    const matchEasy = val2.match(/\d{9}/)?.[0]
    //console.log({matchEasy,matchExact})
    error.value = val && (props.strict ? !matchExact : !matchEasy)
    errorMessage.value = matchEasy && !matchExact ? 'wygląda na niepoprawny numer':null
    const match = props.strict ? matchExact : matchEasy
    if(match && !checking.value) {
        if(props.verify){
            checking.value = true
            showConfirmDialog({
                title:'Weryfikacja numeru',
                message: 'Musimy potwierdzić Twój numer telefonu. Wyślemy na podany numer SMS z kodem weryfikacyjnym. ',
                onCancel:()=>{
                    error.value = errorMessage.value = null
                    nextTick(()=> {
                        checking.value = false
                    })
                },
                onConfirm:()=>{
                    emit('update:phone-number',match?'+48'+match:'')
                    error.value = errorMessage.value = null
                    phone.value = '+48'+match
                    nextTick(()=> {
                        checking.value = false
                    })
                }
            })
        } else {
            emit('update:phone-number',match?'+48'+match:'')
            error.value = errorMessage.value = null
            phone.value = '+48'+match
        }

    }

}
function formatter(val){
    if(!val) return val
    const val2 = val.replace(/\+48/,'').replace(/\D*/g,'')
    return '+48 '+val2.substring(0,9).replace(/(\d{3})\s*/g,' $1')
}
function onBlur(){
    console.log('blur', )
    phone.value = props.phoneNumber
    error.value = errorMessage.value = null
}
if(props.phoneNumber) onUpdate(props.phoneNumber)
watch(()=>props.phoneNumber,val=>{
    if(!val) {
        errorMessage.value = (props.required )?t('Numer telefonu jest wymagany'):null
        error.value = props.required
        phone.value = null
    }
    else if(val!==phone.value) onUpdate(val)
})

</script>

<style scoped>

</style>

