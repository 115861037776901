<template>
    <section>
        <div v-if="inset" @click="opened=!opened" style="margin: 2rem 1.5rem 1rem;display: flex;justify-content: space-between;align-items: center">
            <p>{{ t('Dane osobowe') }}</p>
            <icon :name="opened?'solar:round-alt-arrow-up-linear':'solar:round-alt-arrow-right-bold'"
                  color="var(--van-primary-color)"
                  size="28"></icon>
        </div>
        <TransitionExpand>
            <van-form v-if="opened||!inset" @submit="">
                <VanCellGroup :inset="inset">
                    <ProfileDataFormItem v-for="item in personalData"
                                         :item="item"
                                         :profile="profile"
                                         :mall="mall" :fill-up="fillUp">
                    </ProfileDataFormItem>
                    <section v-if="!fillUp && hasParking">
                        <section v-if="profile?.carPlates?.length">
                            <VanField v-for="(plate,index) in profile.carPlates"
                                      :label="t('Nr rejestracyjny')" label-width="50%"
                                      v-model="profile.carPlates[index]"
                                      :placeholder="t('wpisz numer')"
                                      :disabled="plateChangedToday"
                                      :formatter="carPlateFormatter"
                                      format-trigger="onBlur"
                                      label-class="form-label-faint">
                                <template #right-icon >
                                    <section v-if="!plateChangedToday">
                                        <icon v-show="!(profile.carPlates.length===1 && index===0)"
                                              name="solar:minus-circle-outline"
                                              size="20" @click="removeCarPlate(index)"></icon>
                                        <icon v-show="index===profile.carPlates.length-1 && profile.carPlates.length < maxPlates"
                                              name="solar:add-circle-outline"
                                              size="20" @click="profile.carPlates.push('')"></icon>
                                    </section>

                                </template>
                            </VanField>
                            <!--<pre>{{profile.carPlates}}</pre>-->
                        </section>
                        <section v-else>
                            <VanField :label="t('Nr tablicy rejestracyjnej')" label-width="50%"
                                      v-model="firstCarPlate"
                                      :placeholder="t('wpisz numer')"
                                      :formatter="carPlateFormatter" format-trigger="onBlur"
                                      label-class="form-label-faint" @focus="addFirstCarPlate">
                            </VanField>
                        </section>
                    </section>
                </VanCellGroup>
            </van-form>
        </TransitionExpand>
    </section>
</template>

<script setup>
const props = defineProps({
    profile: Object,
    //mall: Object,
    inset: Boolean,
    fillUp: Boolean
})
const store = useStore()
const mall = computed(()=>store.mall)
const {member} = useMember()
const personalData = computed(()=>{
    if(!props.fillUp) return mall.value?.personalData
            || [{name:'email', label:'E-mail', required:true, prompt:'wprowadź adres', type:'email'}]
    return _filter(mall.value?.personalData,i=>{
        return i.required && _isEmpty(member.profile[i.name])
    })

})

//carPlates

const hasParking = computed(()=>!!mall.value?.parking?.integration)
const maxPlates = computed(()=>mall.value?.parking?.maxPlates)
const firstCarPlate = ref('')
function addFirstCarPlate(){
    _set(props.profile,'carPlates',[firstCarPlate.value||''])
}
function removeCarPlate(index){
    if(props.profile.carPlates.length===1){
        props.profile.carPlates[0]=''
    } else props.profile.carPlates.splice(index,1)
}
const carPlateFormatter = (value)=>value && value.replace(/\s/g,'')?.toUpperCase().replace(/([A-Z]{1,3})(\d*)/,'$1 $2')
const dayjs = useDayjs()
const plateChangedToday = computed(()=> {
    return false
    return props.profile?.carPlateChangedAt && dayjs().isSame(dayjs(props.profile?.carPlateChangedAt), 'day')
})

const opened = ref(true)
watch(()=>props.profile.carPlates,plates=>{
    console.log('plates changed',plates,props.profile.carPlateNumber, carPlateFormatter(props.profile.carPlateNumber))

    if(!plates) return
    const platesConverted = plates.map(p=>p.replace(/\s/g,'')?.toUpperCase())
    const found = props.profile.carPlateNumber && _includes(platesConverted, props.profile.carPlateNumber.replace(/\s/g,'')?.toUpperCase())
    if(!found){
        console.log('changing current plate')
        props.profile.carPlateNumber = plates?.[0] ? plates[0].replace(/\s/g,'').toUpperCase() : null
        props.profile.carPlateChangedAt = new Date()
    }

},{immediate:true,deep:true})


const {t} = useLocale('profileDataForm', {
    en: {
        'Dane osobowe': 'Personal data',
        'Imię':'First name',
        "Nazwisko":"last name",
        'Adres e-mail':'Email',
        'Telefon':'Phone No.',
        'Urodziny':'Birthday',
        'Płeć':'Gender',
        'żeńska':'female',
        'męska':'male',
        'inna':'other',
        'Nr rejestracyjny':'Car plate number',
        'podaj imię':'your name',
        'podaj nazwisko':'your surname',
        'wprowadź adres':'enter address',
        'podaj numer':'enter phone number',
        'podaj datę urodzin':'enter your birthday',
        'wpisz numer':'enter plate'

    },
    uk: {}
})
</script>

<style>
.form-label-faint {
    color:var(--van-text-color-2);
}
</style>