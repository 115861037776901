<template>
    <section>
        <div v-if="inset" @click="opened=!opened" style="margin: 2rem 1.5rem 1rem;display: flex;justify-content: space-between;align-items: center">
            <p>{{ t('Zgody na przetwarzanie danych') }}</p>
            <icon :name="opened?'solar:round-alt-arrow-up-linear':'solar:round-alt-arrow-right-bold'"
                  color="var(--van-primary-color)"
                  size="28">
            </icon>
        </div>
        <TransitionExpand>
            <VanCellGroup v-if="profileConsents && opened" :inset="inset">
                <van-collapse v-model="activeConsents" :border="false" inlist>
                    <div v-if="consentsIntro" style="margin:1rem;font-size: 0.7rem;line-height:1rem;color:var(--van-text-color-2);">
                        <Markdown :content="consentsIntro"></Markdown>
                    </div>
                    <ProfileConsentsTerm v-for="term in memberTerms"
                                         :profile="profile"
                                         :name="term.name"
                                         :key="term.name"
                                         :term="term"
                                         :open="!isTermConfirmed(term)">
                    </ProfileConsentsTerm>
                    <div v-if="showRules && consentsIntro" style="height: 10px;"></div>
                    <ProfileConsentsRule v-for="(consent) in memberConsents"
                                         :rule="consent"
                                         :profile="profile"
                                         :mall="mall">
                    </ProfileConsentsRule>
                </van-collapse>
            </VanCellGroup>
        </TransitionExpand>

    </section>
</template>

<script setup>
const props = defineProps({
    profile: Object,
    inset: Boolean,
    showRules: Boolean,
    fillUp: Boolean,
})
const {user} = useAuth()
const store = useStore()
const mall = computed(()=>store.mall)
const showParkingRules = computed(()=>!_isEmpty(props.profile.carPlates?.[0]))
const mallTerms = computed(()=> store?.mall.terms)
const terms = computed(()=>{
    return _filter(mallTerms.value,t=>{
        return (t.required || calculate(t.requiredFormula))
    })
})
function isTermConfirmed(term){
    return props.profile?.terms?.[term.name] > term.date
}
const mallConsents = computed(()=> store?.mall?.consents?.rules)
const profileConsents = computed(()=>props.profile?.consents)
function isConsentConfirmed(consent){
    return _find(props.profile?.consents,{name:consent.name})?.value
}
const activeConsents = ref([])
const consentsIntro = computed(()=> store.mall?.consents?.introMessage
        ?? t('Administratorem zbioru danych osobowych jest spółka **Mall Promo** Sp. z o.o. z siedzibą w Krakowie.')
)
const opened = ref(true)
const {calculate} = useJsExpression()
watch(()=>props.profile,val=>{
    _each(mallConsents.value,(consent)=>{
        const isRequired = consent.requiredFormula ? calculate(consent.requiredFormula,{profile:val}) : consent.required
        if(isRequired && !_find(val.consents,{name:consent.name})?.value){
            if(!_includes(activeConsents.value,consent.name))activeConsents.value.push(consent.name)
        }
    })
    _each(mallTerms.value,(term)=>{
        if(!isTermConfirmed(term)){
            if(!_includes(activeConsents.value,term.name)) activeConsents.value.push(term.name)
        }
        else _remove(activeConsents.value,v=>v===term.name)
    })
    //console.log(activeConsents.value, props.profile.terms)
},{immediate:true,deep:true})

const memberTerms = ref([])
const memberConsents = ref([])
onMounted(()=>{
    if(!props.fillUp){
        memberTerms.value = terms.value
        memberConsents.value = mallConsents.value
    } else {
        memberTerms.value = _filter(terms.value,term=>!isTermConfirmed(term))
        memberConsents.value = _filter(mallConsents.value,consent=>!isConsentConfirmed(consent))
    }

})


const {t} = useLocale('profileDataConsents', {
    en: {
        'Zgody na przetwarzanie danych': 'Consents for data processing',
        'Imię':'First name',
        "Nazwisko":"last name",
        'Adres e-mail':'Email',
        'Nr telefonu':'Phone No.',
        'Urodziny':'Birthday',
        'Płeć':'Gender',
        'Nr tablicy rejestracyjnej':'Car plate numbers',
        'podaj imię':'your name',
        'podaj nazwisko':'your surname',
        'wprowadź adres':'enter address',
        'podaj numer':'enter phone number',
        'podaj datę urodzin':'enter your birthday',
        'wpisz numer':'enter plate'

    },
    uk: {}
})
</script>

<style scoped>
.rules-container {
    background-color: var(--van-background);
    height:40vh;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 1rem;
    margin: -1rem 0;
    font-size:0.5rem;
}
</style>