<template>
    <InputPhone v-if="item.name==='phoneNumber'"
                v-model:phone-number="profile.phoneNumber"
                :placeholder="t(item.prompt)"
                strict
                :verify="false"
                :disabled="isDisabled"
                :required="isRequired"
                label-class="form-label-faint">
        <template #label>
            {{t(item.label)}}<span v-if="isRequired" style="color:var(--van-danger-color)">*</span>
        </template>
        <template #error-message v-if="error">
            {{error}}
        </template>
    </InputPhone>
    <VanField v-else-if="item.name==='birthday'"
              is-link
              readonly
              :modelValue="profile.birthday ? $dayjs(profile.birthday).locale(locale.lang).format('DD MMMM YYYY'):''"
              @click="showPicker = true"
              :placeholder="t(item.prompt)"
              :disabled="isDisabled"
              label-class="form-label-faint">
        <template #label>
            {{t(item.label)}}<span v-if="isRequired" style="color:var(--van-danger-color)">*</span>
        </template>
    </VanField>
    <VanField v-else-if="item.name==='gender'"
              label-class="form-label-faint">
        <template #input>
            <van-radio-group :model-value="profile.gender" @update:model-value="onGenderUpdate"
                             direction="horizontal" style="margin-bottom:-10px;">
                <van-radio name="female" style="margin-bottom: 10px;">{{ t('żeńska') }}</van-radio>
                <van-radio name="male" style="margin-bottom: 10px;">{{ t('męska') }}</van-radio>
                <van-radio name="other" style="margin-bottom: 10px;">{{ t('inna') }}</van-radio>
            </van-radio-group>
        </template>
        <template #label>
            {{t(item.label)}}<span v-if="isRequired" style="color:var(--van-danger-color)">*</span>
        </template>
    </VanField>
    <VanField v-else
              v-model="profile[item.name]"
              :placeholder="t(item.prompt)"
              :disabled="isDisabled"
              :error-message="error"
              label-class="form-label-faint">
        <template #label>
            {{t(item.label)}}<span v-if="isRequired" style="color:var(--van-danger-color)">*</span>
        </template>
        <template #error-message v-if="error">
            {{error}}
        </template>
    </VanField>
    <van-popup v-model:show="showPicker"
               position="bottom"
               teleport="body"
               round>
        <van-date-picker v-model="birthday"
                         :title="t('Data urodzenia')"
                         :min-date="datesRange[0]"
                         :max-date="datesRange[1]"
                         :columns-type="['day','month','year']"
                         :formatter = "dateFormatter"
                         @confirm="showPicker = false"
                         @cancel="showPicker = false">
        </van-date-picker>
    </van-popup>
</template>

<script setup>
const props = defineProps({
    profile: Object,
    mall: Object,
    item: Object,
    fillUp:Boolean
})
const {calculate} = useJsExpression()
const isRequired = computed(()=>{
    if(props.item.requiredFormula) {
        return calculate( props.item.requiredFormula, {profile:props.profile})||false
    } else return props.item.required||false
})
const isDisabled = computed(()=>{
    if(props.item.name==='email'&&!props.fillUp) return false
    if(props.item.disabledFormula) {
        return calculate( props.item.disabledFormula, {profile:props.profile})||false
    } else return props.item.disabled||false
})
const error = computed(()=>{
    return isRequired.value && _isEmpty(props.profile[props.item.name]) && t('wartość nie może być pusta') ||''
})

function onGenderUpdate(val){
    if(props.profile.gender===val) props.profile.gender = null
    else props.profile.gender = val
}

const datesRange = ref([
    new Date('1900-01-01'),
    new Date('2010-01-01'),
])
const dayjs = useDayjs()
const dateFormatter = (type, option)=>{
    if(type==='month'){
        option.text = dayjs(new Date(2000,Number(option.text)-1,5)).locale(locale.lang).format('MMMM')
    }
    return option
}





const currentDate = ref(['2000', '04', '01']);
const showPicker = ref(false)
const birthday = computed({
    get:()=> {
        if(!props.profile.birthday) return [1,4,2000]
        else return [props.profile.birthday.getDate(), props.profile.birthday.getMonth() + 1, props.profile.birthday.getFullYear()]
    },
    set:(val)=>{
        props.profile.birthday = new Date (val[2],val[1]-1,val[0])
    }
})

const {t} = useLocale('profileDataForm', {
    en: {
        'Dane osobowe': 'Personal data',
        'Imię':'First name',
        "Nazwisko":"last name",
        'Adres e-mail':'Email',
        'Telefon':'Phone No.',
        'Urodziny':'Birthday',
        'Płeć':'Gender',
        'żeńska':'female',
        'męska':'male',
        'inna':'other',
        'Nr tablicy rejestracyjnej':'Car plate numbers',
        'podaj imię':'your name',
        'podaj nazwisko':'your surname',
        'wprowadź adres':'enter address',
        'podaj numer':'enter phone number',
        'podaj datę urodzin':'enter your birthday',
        'wpisz numer':'enter plate'

    },
    uk: {}
})
</script>

<style scoped>

</style>