<template>
    <suspense>
        <VanPopup v-model:show="show"
                  position="bottom"
                  :round="false"
                  safe-area-inset-bottom
                  safe-area-inset-top
                  closeable
                  :before-close="beforeClose"
                  style="max-width:min(100vw, 600px);">
            <div v-if="show" class="full-screen">
                <p class="subtitle" style="text-align: center;padding-top: 1rem;">{{ t('Rejestracja uczestnika') }}</p>
                <br>
                <VanCell :title="profile.displayName||'- '" center title-style="flex-grow:3">
                    <template #label>
                        <div style="display: flex;flex-direction: column;">
                            <div>
                                <VanIcon name="envelop-o" style="margin-right: 5px;"></VanIcon>
                                {{profile?.email}}
                                <VanIcon :name="profile?.emailVerified?'success':'cross'"
                                         :color="profile?.emailVerified?'var(--van-success-color)':'var(--van-danger-color)'">
                                </VanIcon>
                            </div>
                            <div>
                                <VanIcon name="phone-o" style="margin-right: 5px;"></VanIcon>
                                {{profile?.phoneNumber}}
                                <VanIcon :name="profile?.phoneVerified?'success':'question-o'"
                                         :color="profile?.phoneVerified?'var(--van-success-color)':'var(--van-danger-color)'">
                                </VanIcon>
                            </div>
                        </div>
                    </template>
                    <template #value>
                        <div>
                            <ProfileAvatarPicker :avatar="profile?.avatar"
                                                 @select="onAvatar">
                            </ProfileAvatarPicker>
                        </div>
                    </template>
                </VanCell>
                <br>
                <ProfileDataForm :profile="profile"></ProfileDataForm>
                <br>
                <VanCell v-if="!missingItems.length && memberships?.active"
                         title="Wybierz rodzaj konta"
                         :border="false" class="animate__animated animate__fadeIn animate__delay-1s">
                    <template #title>
                        Wybierz rodzaj konta<span style="color:var(--van-danger-color)">*</span>
                    </template>
                    <template #label>
                        <van-radio-group v-model="profile.membership">
                            <div v-for="level in _filter(memberships?.levels,l=>!l.hide)" class="account-description">
                                <van-radio :name="level.label">
                                    <VanCell :title="level.name" style="flex-grow: 8">
                                        <template #label>
                                            {{level.description}}
                                        </template>
                                    </VanCell>
                                </van-radio>
                            </div>
                        </van-radio-group>
                        <div v-if="profile.membership!=='standard'" class="animate__animated animate__fadeIn"
                             style="font-size: 0.6rem;margin: 1rem 0.5rem 0;line-height: 0.9rem;">
                            <Markdown :content="memberships?.legalNotice"></Markdown>
                        </div>
                    </template>
                </VanCell>
                <br>

                <ProfileDataConsents v-if="profile.membership || !memberships?.active"
                                     :key="profile?.uid"
                                     :mall="store.mall"
                                     :profile="profile"
                                     show-rules>
                </ProfileDataConsents>

                <div>
                    <div style="margin: 1rem 1rem;display: flex;gap:1rem;justify-content: center">
                        <van-button round type="danger" plain size="small" style="padding: 0 1rem;" @click="beforeClose">
                            Rezygnuję
                        </van-button>
                        <van-button v-if="readyToSave"
                                    round
                                    type="primary"
                                    size="small"
                                    style="padding: 0 1rem;" @click="saveProfile">
                            Zapisz zmiany
                        </van-button>
                    </div>
                    <div v-if="auth.currentUser" style="margin: 1rem;">
                        <p style="font-size: 0.7rem; text-align: center; margin-top: 1rem;color:var(--van-text-color-2)">ID użytkownika: {{auth.currentUser.uid}}</p>
                    </div>
                    <div style="margin:3rem"></div>
                </div>
                <br><br>
            </div>
        </VanPopup>

    </suspense>
    <!--
    <VanDialog v-model:show="phoneVerify" :show-confirm-button="false">
        <div>
            <LoginVerifyPhone :phone-number="profile.phoneNumber" @success="onPhoneVerified"></LoginVerifyPhone>
        </div>
    </VanDialog>
    -->

</template>

<script setup>

const props = defineProps({

})
const show = ref(false)
const store = useStore()
const {auth} = useAuth()
const memberships = computed(()=>store?.mall?.memberships)

const profile = ref({})
console.log('creating new profile...',profile)
onMounted(()=>{
    const user = {authUser:auth.currentUser}
    profile.value = {
        phoneNumber:user.authUser?.phoneNumber||null,
        photoURL:null,
        tenantId:user.authUser?.tenantId||null,
        uid:user.authUser?.uid,
        email:user.authUser?.email||null,
        emailVerified: user.authUser?.emailVerified,
        phoneVerified: !!user.authUser?.phoneNumber,
        providerData: user.authUser?.providerData||null,
        isAnonymous: user.authUser?.isAnonymous,
        displayName: user.authUser.displayName||null,
        userCreatedAt: user.authUser.createdAt ? (new Date(Number(user?.authUser.createdAt))||null):null,
        createdAt: new Date(),
        consents:_map(store?.mall?.consents?.rules,r=>{
            return {
                ...r,
                value: false//r.required
            }
        }),
        avatar: null,
        membership: null,
        mall:{
            id: store.mall?.id,
            name: store.mall?.name
        },
        firstName: user?.authUser?.displayName && user?.authUser?.displayName[0]!=='+' ? (user.authUser.displayName.split(' ')[0]||null):null,
        lastName: user?.authUser?.displayName && user?.authUser?.displayName[0]!=='+' ? (user.authUser.displayName.split(' ')?.[1]||null):null,
        birthday:null,
        gender:null,
        carPlates:['']
    }

})
setTimeout(()=>{
    show.value=true
},100)
function onAvatar(val){
    profile.value.avatar = val.avatar
    if(!profile.value.gender) profile.value.gender= val.female?'female':'male'
}
watch(()=>(profile.value.firstName+' '+profile.value.lastName),val=>{
    profile.value.displayName = val?.trim?.()||null
})
const {calculate} = useJsExpression()
const consented = computed(()=>{
    return _reduce(store.mall.consents.rules,(acc,consent)=>{
        const profileConsent = _find(profile.value.consents,{name:consent.name})
        const isRequired = consent.requiredFormula ? calculate(consent.requiredFormula,{profile:profile.value}) : consent.required
        return acc && (profileConsent?.value || !isRequired)
    }, true)
})
const missingItems = computed(()=>{
    return _map(_filter(store.mall.personalData, item=>{
        const isRequired = item.requiredFormula ? calculate(item.requiredFormula,{profile:profile.value}) : item.required
        return _isEmpty(profile.value[item.name]) && isRequired
    }), i => i.label)
})
const readyToSave = computed(()=>{
    return consented.value && !missingItems.value.length
})

const phoneVerify = ref(false)
watch(()=>profile.value.phoneNumber,val=>{
    console.log('got phone number',val)
    if(val && !auth.currentUser?.phoneNumber){
        //check if it exists already
        const {cloudFunction} = useFirebase()
        const checkPhone = cloudFunction('member-checkAccountByPhone')
        checkPhone({
            phone: val,
            authTenant: store.tenantId
        }).then(result=>{
            console.log('check result',result)
            if(result.data?.exists){
                showDialog({
                    title:t('Konto istnieje'),
                    allowHtml:true,
                    message:t(`Tel: <b>${val}</b>.<br>Istnieje już konto powiązane z tym numerem telefonu. Jeśli to pomyłka w numerze kliknij "Popraw".`),
                    confirmButtonText:t('Popraw'),
                    showCancelButton: true
                }).then(()=>{
                    profile.value.phoneNumber = null
                }).catch(()=>{
                    return navigateTo('/profile').then(()=>{
                        const {logout} = useAuth()
                        return logout()
                    }).then(()=>{
                        show.value = false
                    })
                })
            } else {
                // should verify phone number
                console.log('phone not in use - should verify it')
                phoneVerify.value = true

            }
        }).catch(err=>console.error)
    }
})




function saveProfile(){
    console.log('saveProfile...',profile.value)
    const {doc, setDoc} = useFirebase()
    setDoc(doc(`members/${profile.value.uid}`),profile.value,{merge:true}).then(()=>{
        showSuccessToast('OK')
    }).catch(err=>{
        showDialog({
            title:t('Błąd'),
            message:err.message
        })
    }).finally(()=>{
        show.value = false
        store.machines.authFlow.send({type:'DONE'})
    })
}

async function beforeClose(){
    console.log('beforeClose...',profile.value)
    const {logout} = useAuth()
    return showConfirmDialog({
        title:t('Chcę zrezygnować z rejestracji.'),
        allowHtml:true,
        message:t('<br></br>Rozumiem, że żadne dane nie zostaną zapisane.'),
        cancelButtonText:t('Ojej, nie!'),
        confirmButtonText:t('Potwierdzam'),
    }).then(()=>{
        return navigateTo('/')
    }).then(()=>{
        console.log('navigated to /')
        return logout()
    }).then(()=>{
        console.log('successful logout')
        show.value = false
        return Promise.resolve()
    }).catch(err=>{
        return Promise.reject()
    })
}


const {t} = useLocale('signUpForm',{
    en:{
        'Rejestracja uczestnika':'Participant registration',
        'Wybierz rodzaj konta':'Chose Account Level',
        'Standardowe':"Standard account",
        "Premium":'Premium'
    },
    uk:{


    },
    pl:{
        standardBenefits:'Otrzymujesz wszystkie standardowe korzyści, ale bez dostępu do wyjątkowych ofert Premium.',
        premiumBenefits:'Zbierasz punkty szybciej w promocjach dla uczestników Premium, ' +
                'masz dostęp do dedykowanych ofert Premium, możemy Ci także wysyłać dopasowany newsletter.',
        smallPrint:'Włączenie opcji "Premium" powoduje zaznaczenie wszystkich zgód na komunikację.' +
                ' W przyszłości możesz wycofać te zgody, ale spowoduje to zmianę konta na Standardowe.'

    }
})
</script>

<style scoped>

.rules-container {
    background-color: var(--van-background);
    height:40vh;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 1rem;
    margin: -1rem 0;
    font-size:0.6rem;
}
.account-description {
    padding: 0 0.5rem;
    border:1px solid var(--van-border-color);
    border-radius: 10px;
    margin-top: 0.5rem;
}
</style>