<template>
    <van-popover v-model:show="showPopover"
                 placement="bottom-end"
                 theme="dark">
        <div style="display: flex;flex-wrap: wrap; width:260px;justify-content: center;align-items: center;margin-top: 5px;">
            <div  v-for="i in 16" style="margin:7px;">
                <VanImage :src="'/avatars/'+i+'avatar.png'"
                          width="50px"
                          height="50px"
                          @click="onSelect(i)">
                </VanImage>
            </div>
        </div>
        <template #reference>
            <div style="padding-top: 10px;">
                <VanImage :src="avatar||'/avatars/empty-avatar.png'" width="70px" height="70px">
                    <p v-if="!avatar" style="text-align: center;margin-top: -23px; color:#aaa;font-size:0.75rem;">{{ t('wybierz') }}</p>
                </VanImage>
            </div>
        </template>
    </van-popover>
</template>

<script setup>
const props = defineProps({
    avatar: String
})
const emit = defineEmits(['select'])
const {user} = useAuth()
const profile = computed(()=>user.profile)
const showPopover = ref(false)
const avatarsMale = ref([3,4,11,12,14,15,16])
function onSelect(i){
    emit('select',{
        avatar:`/avatars/${i}avatar.png`,
        female: !_includes(avatarsMale.value,i)
    })
    showPopover.value = false
}

</script>

<style scoped>
.empty-prompt {
    height:70px;
    width:70px;
    border-radius: 35px;
    background-color: #00000080;
    color:white;
    padding:0;
    display: flex;
    justify-content: center;
    align-items: center;
}

</style>